import { useMemo } from 'react'
import {
  Column,
  Container,
  Icon,
  IconNames,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { LogoButton } from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/LogoButton'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './footerGiftcard.module.css'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'

type InfoBlockType = {
  title: string
  desc: string
  icon: IconNames
  fontSize?: TFontSize
  onClick?: () => void
}

export type FooterGiftcardProps = {
  removeDescription?: boolean
  paddingVertical?: string
  marginTop?: string
}

const email = 'soporte.giftcard@smu.cl'

const InfoBlock = ({
  title,
  desc,
  icon,
  fontSize = 'sm',
  onClick
}: InfoBlockType) => {
  return (
    <Row alignItems='center'>
      <Icon
        clickable='pointer'
        color='white'
        customSize={30}
        margin='0 0.75rem 0 0'
        name={icon}
        onClick={onClick}
      />
      <Column>
        <Text
          color='white'
          fontSize={fontSize}
          fontWeight='semibold'
          type='p'
        >
          {title}
        </Text>
        <Text
          color='white'
          customClassName={styles['text--pointer']}
          fontSize={fontSize}
          fontWeight='semibold'
          onClick={onClick}
          type='p'
        >
          {desc}
        </Text>
      </Column>
    </Row>
  )
}

export const FooterGiftcard = ({
  removeDescription = false,
  paddingVertical = '3rem',
  marginTop = '2.938rem'
}: FooterGiftcardProps) => {
  const { innerWidth } = useMobile()

  const isMobile = innerWidth < 1280
  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <BigScreen>
        <Container
          background='linear-gradient(93.08deg, #E81D75 -0.01%, #E93B54 110.49%)'
          isWrap
          padding={`${paddingVertical} 5rem`}
          position='relative'
          tagName='footer'
        >
          {!removeDescription && (
            <Row>
              <Column width='30%'>
                <LogoButton logo='GiftCardWhiteLogo' />
              </Column>
              <Column width='70%'>
                <Text
                  color='white'
                  fontSize='sm'
                  type='p'
                >
                  Información importante sobre concursos:
                </Text>
                <Text
                  color='white'
                  fontSize='sm'
                  textAlign='justify'
                  type='p'
                >
                  En función de ciertos reportes que nos han llegado por
                  diversos canales, consideramos importante señalar que los
                  concurso de Unimarc, Mayorista 10, Alvi y Super 10 son
                  comunicados únicamente mediante sus canales oficiales (sitio
                  web, Facebook, Instagram o email corporativo), que no se
                  entregan premios por concursos en otras plataformas, ya sea
                  por compras específicas de productos o llamados telefónicos, y
                  que nunca solicitaremos depósitos de dinero para acceder a
                  algún beneficio. Hacemos un llamado a tener cuidado con este
                  tipo de situaciones y a no tomar en cuenta concursos que no
                  sean comunicados a través de canales oficiales.
                </Text>
              </Column>
            </Row>
          )}
          <Row margin={`${marginTop} 0 0 0`}>
            <Column>
              <InfoBlock
                desc='600 600 0025'
                icon='PhoneOutline'
                onClick={() => {
                  window.open(`tel:6006000025`)
                }}
                title='Servicio al cliente'
              />
            </Column>
            <Column>
              <InfoBlock
                desc={email}
                icon='MailOutline'
                onClick={() => {
                  window.open(`mailto:${email}`)
                }}
                title='Contacto'
              />
            </Column>
            <Column>
              <InfoBlock
                desc='#laGiftCarddeChile'
                icon='TermsOutline'
                onClick={() => {
                  window.open(
                    'https://www.lagiftcarddechile.cl/content/uploads/2023/09/TC-Giftcard-de-Chile_VF-12.09.2023.pdf',
                    '_blank'
                  )
                }}
                title='Términos y condiciones'
              />
            </Column>
            <Column>
              <Text
                color='white'
                fontSize='sm'
                fontWeight='semibold'
                type='p'
              >
                Descarga la APP
              </Text>
              <Row margin='7px 0 0 0'>
                <Icon
                  clickable='pointer'
                  color='white'
                  customSize={30}
                  margin='0 0.75rem 0 0'
                  name='GooglePlay'
                />
                <Icon
                  clickable='pointer'
                  color='white'
                  customSize={30}
                  margin='0 0.75rem 0 0'
                  name='AppStore'
                />
              </Row>
            </Column>
          </Row>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          background='linear-gradient(93.08deg, #E81D75 -0.01%, #E93B54 110.49%)'
          isWrap
          padding='1rem 0.75rem'
          position='relative'
          tagName='footer'
        >
          {!removeDescription && (
            <>
              <Row margin='0 0 1.5rem 0'>
                <LogoButton logo='GiftCardWhiteLogo' />
              </Row>
              <Row>
                <Text
                  color='white'
                  fontSize='sm'
                  type='p'
                >
                  Información importante sobre concursos:
                </Text>
              </Row>
              <Row>
                <Text
                  color='white'
                  fontSize='sm'
                  type='p'
                >
                  En función de ciertos reportes que nos han llegado por
                  diversos canales, consideramos importante señalar que los
                  concurso de Unimarc, Mayorista 10, Alvi, Super 10 y
                  Telemercados son comunicados únicamente mediante sus canales
                  oficiales (sitio web, Facebook, Instagram o email
                  corporativo), que no se entregan premios por concursos en
                  otras plataformas, ya sea por compras específicas de productos
                  o llamados telefónicos, y que nunca solicitaremos depósitos de
                  dinero para acceder a algún beneficio. Hacemos un llamado a
                  tener cuidado con este tipo de situaciones y a no tomar en
                  cuenta concursos que no sean comunicados a través de canales
                  oficiales.
                </Text>
              </Row>
            </>
          )}
          <Row
            margin={`${removeDescription ? '0.5rem' : '2.5rem'} 0 1.75rem 0`}
          >
            <InfoBlock
              desc='600 600 0025'
              fontSize='md'
              icon='PhoneOutline'
              onClick={() => {
                window.open(`tel:6006000025`)
              }}
              title='Servicio al cliente'
            />
          </Row>
          <Row margin='0 0 1.75rem 0'>
            <InfoBlock
              desc='soporte.giftcard@smu.cl'
              fontSize='md'
              icon='MailOutline'
              onClick={() => {
                window.open(`mailto:${email}`)
              }}
              title='Contacto'
            />
          </Row>
          <Row margin='0 0 1.75rem 0'>
            <InfoBlock
              desc='#laGiftCarddeChile'
              fontSize='md'
              icon='TermsOutline'
              onClick={() => {
                window.open(
                  'https://www.lagiftcarddechile.cl/content/uploads/2023/09/TC-Giftcard-de-Chile_VF-12.09.2023.pdf',
                  '_blank'
                )
              }}
              title='Términos y condiciones'
            />
          </Row>
          <Row>
            <Column>
              <Text
                color='white'
                fontSize='sm'
                fontWeight='semibold'
                type='p'
              >
                Descarga la APP
              </Text>
              <Row margin='7px 0 0 0'>
                <Icon
                  clickable='pointer'
                  color='white'
                  customSize={30}
                  margin='0 0.75rem 0 0'
                  name='GooglePlay'
                />
                <Icon
                  clickable='pointer'
                  color='white'
                  customSize={30}
                  margin='0 0.75rem 0 0'
                  name='AppStore'
                />
              </Row>
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </ResponsiveContext.Provider>
  )
}
